import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router";
import { Loading } from "../../components/loading";
import { AlertContext } from "../../contexts/alert-context";
import { StoreOrderContext } from "../../contexts/store-order-context";
import { StoreVendorContext } from "../../contexts/store-vendor-context";
import { useSelectedStore } from "../../hooks/selected-store";

export function NewOrder() {
  const [_, setSubmitted] = useState(false);
  const { selectedStore, loading: storeLoading } = useSelectedStore();
  const { vendors, loading: vendorsLoading } = useContext(StoreVendorContext);
  const { createOrder } = useContext(StoreOrderContext);

  const [searchParameters] = useSearchParams();
  const vendorId = useMemo(() => {
    return searchParameters.get("vendorId");
  }, [searchParameters]);

  const { addErrorAlert } = useContext(AlertContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!vendorId) {
      addErrorAlert("Vendor ID is required", { keepAfterRouteChange: true });
    }
  }, [vendorId, addErrorAlert]);

  useEffect(() => {
    if (selectedStore && !vendorsLoading && vendorId) {
      const vendor = vendors.find((v) => v.vendor_id === vendorId);

      if (!vendor) {
        addErrorAlert("Vendor not found", { keepAfterRouteChange: true });
      }
      if (!selectedStore) {
        addErrorAlert("Store not found", { keepAfterRouteChange: true });
      }

      setSubmitted((submitted) => {
        if (submitted) {
          console.error("CreateOrder already called");
          return true;
        }
        createOrder(selectedStore.id, { vendor_id: vendorId, items: [] })
          .then((order) => {
            navigate(`/stores/${selectedStore.id}/orders/${order.id}/edit`);
          })
          .catch(() => {
            addErrorAlert("Error creating new order", {
              keepAfterRouteChange: true,
            });
          });
        return true;
      });
    }
  }, [
    selectedStore,
    vendorsLoading,
    vendors,
    addErrorAlert,
    vendorId,
    navigate,
    createOrder,
  ]);

  if (storeLoading || vendorsLoading) {
    return <Loading />;
  }
  return "";
}
