import { useAuth0 } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";
import { Route, Routes } from "react-router";

import AuthenticationGuard from "./auth/authentication-guard";
import AlertsView from "./components/alerts-view";
import Footer from "./components/footer";
import { Loading } from "./components/loading";
import NavBar from "./components/navs/nav-bar";
import { Updater } from "./components/updater";
import { AlertContextProvider } from "./contexts/alert-context";
import { Compose } from "./contexts/compose";
import { StoreContextProvider } from "./contexts/store-context";
import { StoreOrderContextProvider } from "./contexts/store-order-context";
import { StoreProductContextProvider } from "./contexts/store-product-context";
import { StoreVendorContextProvider } from "./contexts/store-vendor-context";
import { Dashboard } from "./pages/dashboard";
import { PublicHomePage } from "./pages/public-home";
import { Signup } from "./pages/signup";
import Stores from "./pages/stores";

const contexts = [
  AlertContextProvider,
  StoreContextProvider,
  StoreVendorContextProvider,
  StoreOrderContextProvider,
  StoreProductContextProvider,
];

const App = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (import.meta.env.MODE === "production" && isAuthenticated && user) {
      console.log(
        `Setting RUM user to id: ${user.sub}, name: ${user.name}, email: ${user.email}`,
      );

      datadogRum.setUser({
        id: user.sub,
        name: user.name,
        email: user.email,
      });
    }
  }, [isAuthenticated, user]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Compose components={contexts}>
      <div id="app" className="h-full grid grid-rows-[auto,1fr,auto]">
        <NavBar />
        <div className="h-full overflow-hidden">
          <div className="h-full overflow-y-auto flex flex-col">
            <Routes>
              <Route path="/signup" element={<Signup />} />

              <Route
                path="/"
                element={isAuthenticated ? <Dashboard /> : <PublicHomePage />}
              />

              <Route path="/stores">
                <Route
                  path="*"
                  element={<AuthenticationGuard component={Stores} />}
                />
              </Route>
            </Routes>
          </div>
        </div>
        <Footer />
        <Updater />
      </div>
      <AlertsView />
    </Compose>
  );
};

export default App;
