import { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import { SmallSecondaryButton } from "../../components/buttons";
import { DownloadUploaderButton } from "../../components/download-uploader-button";
import { Modal } from "../../components/modal";
import { NoResults } from "../../components/no-results";
import { ReceiveInvoices } from "../../components/receive-invoices";
import { Cell, Row, Table } from "../../components/tables";
import type { Vendor } from "../../data/vendor";
import { useSelectedStore } from "../../hooks/selected-store";
import { useVendorsQuery } from "../../hooks/vendors";

export function Vendors() {
  const { data: vendors } = useVendorsQuery();
  const { selectedStore } = useSelectedStore();
  const navigate = useNavigate();
  const [receiveInvoicesVendor, setReceiveInvoicesVendor] = useState<
    Vendor | undefined
  >();
  const newVendorOrder = useCallback(
    (vendor: Vendor) => {
      if (!selectedStore) return;
      navigate(
        `/stores/${selectedStore.id}/orders/new?vendorId=${vendor.vendor_id}`,
      );
    },
    [navigate, selectedStore],
  );
  const editVendor = useCallback(
    (vendor: Vendor) => {
      if (!selectedStore) return;
      navigate(`/stores/${selectedStore.id}/vendors/${vendor.vendor_id}/edit`);
    },
    [navigate, selectedStore],
  );

  return (
    <>
      {vendors.length > 0 && (
        <div className="container mx-auto">
          <Table
            headings={[
              "ID",
              "Name",
              "Vendor Code",
              "Account #",
              "Terms",
              "Emails",
              "Enabled",
              "",
            ]}
          >
            {vendors.map((vendor) => (
              <Row key={vendor.vendor_id}>
                <Cell>{vendor.vendor_id}</Cell>
                <Cell>{vendor.name}</Cell>
                <Cell>{vendor.normalized_name}</Cell>
                <Cell>{vendor.account_number}</Cell>
                <Cell>{vendor.terms}</Cell>
                <Cell>
                  <ul>
                    {(vendor.order_settings.representatives || []).map(
                      (rep, index) => (
                        <li key={index}>{rep.email}</li>
                      ),
                    )}
                  </ul>
                </Cell>
                <Cell>{vendor.disabled ? "❌" : "✅"}</Cell>
                <Cell>
                  <div className="flex gap-2">
                    <SmallSecondaryButton onClick={() => editVendor(vendor)}>
                      Edit...
                    </SmallSecondaryButton>
                    {!vendor.disabled && (
                      <SmallSecondaryButton
                        onClick={() => newVendorOrder(vendor)}
                      >
                        New Order
                      </SmallSecondaryButton>
                    )}
                    {vendor.invoice_formats.length > 0 && (
                      <SmallSecondaryButton
                        onClick={() => setReceiveInvoicesVendor(vendor)}
                      >
                        Receive Invoices
                      </SmallSecondaryButton>
                    )}
                  </div>
                </Cell>
              </Row>
            ))}
          </Table>
        </div>
      )}
      {vendors.length === 0 && (
        <NoResults label="You haven't imported any vendors yet.">
          <DownloadUploaderButton />
        </NoResults>
      )}
      <Modal
        visible={receiveInvoicesVendor !== undefined}
        title="Receive Invoices"
        onClose={() => setReceiveInvoicesVendor(undefined)}
      >
        <ReceiveInvoices vendor={receiveInvoicesVendor} />
      </Modal>
    </>
  );
}
