import { datadogRum } from "@datadog/browser-rum";
import { createContext, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";

export type AlertKind = "Success" | "Error" | "Info" | "Warning";

type AlertOptions = {
  autoCloseAfterTimeMS?: number;
  keepAfterRouteChange?: boolean;
  details?: string;
};

export type Alert = {
  id: string;
  message: string;
  kind: AlertKind;
} & AlertOptions;

type AlertContext = {
  alerts: Alert[];
  addErrorAlert: (message: string, options?: AlertOptions) => void;
  addSuccessAlert: (message: string, options?: AlertOptions) => void;
  addInfoAlert: (message: string, options?: AlertOptions) => void;
  addWarningAlert: (message: string, options?: AlertOptions) => void;
  removeAlert: (alert: Alert) => void;
};

export const AlertContext = createContext<AlertContext>({
  alerts: [],
  addErrorAlert: () => {},
  addSuccessAlert: () => {},
  addInfoAlert: () => {},
  addWarningAlert: () => {},
  removeAlert: () => {},
});

const alertDefaults: AlertOptions = {
  keepAfterRouteChange: false,
  autoCloseAfterTimeMS: 60_000,
  details: undefined,
};

export const AlertContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const location = useLocation();
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const addAlert = useCallback(
    (message: string, kind: AlertKind, options?: AlertOptions) => {
      const alert: Alert = {
        ...alertDefaults,
        ...options,
        message,
        kind,
        id: crypto.randomUUID(),
      };
      setAlerts((alerts) => [...alerts, alert]);

      // auto close alert if required
      if (alert.autoCloseAfterTimeMS) {
        setTimeout(() => {
          setAlerts((alerts) => alerts.filter((x) => x.id !== alert.id));
        }, alert.autoCloseAfterTimeMS);
      }
    },
    [],
  );

  const addErrorAlert = useCallback(
    (message: string, options?: AlertOptions) => {
      addAlert(message, "Error", options);
      datadogRum.addError(message);
    },
    [addAlert],
  );

  const addSuccessAlert = useCallback(
    (message: string, options?: AlertOptions) => {
      addAlert(message, "Success", options);
    },
    [addAlert],
  );

  const addInfoAlert = useCallback(
    (message: string, options?: AlertOptions) => {
      addAlert(message, "Info", options);
    },
    [addAlert],
  );

  const addWarningAlert = useCallback(
    (message: string, options?: AlertOptions) => {
      addAlert(message, "Warning", options);
    },
    [addAlert],
  );

  const removeAlert = useCallback((alert: Alert) => {
    setAlerts((alerts) => alerts.filter((x) => x.id !== alert.id));
  }, []);

  // clear alerts on location change
  useEffect(() => {
    // TODO: check if this is actually needed or works
    // don't clear if pathname has trailing slash because this will be auto redirected again
    if (location.pathname.endsWith("/")) return;

    setAlerts((alerts) => {
      const filteredAlerts = alerts.filter((x) => x.keepAfterRouteChange);
      // if there were no alerts to remove, return the original array to not cause unnecessary renders
      if (filteredAlerts.length === alerts.length) return alerts;
      return filteredAlerts;
    });
  }, [location]);

  return (
    <AlertContext.Provider
      value={{
        alerts,
        addErrorAlert,
        addSuccessAlert,
        addInfoAlert,
        addWarningAlert,
        removeAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};
